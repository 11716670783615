export default {
  "comp-sets-page": "_comp-sets-page_147r1w",
  "header-alert": "_header-alert_147r1w",
  "header-alert-image": "_header-alert-image_147r1w",
  "actionable-section": "_actionable-section_147r1w",
  "cta-button": "_cta-button_147r1w",
  "main-section": "_main-section_147r1w",
  "reviews-wrapper": "_reviews-wrapper_147r1w",
  "review-item": "_review-item_147r1w",
  "bottom-section": "_bottom-section_147r1w",
  "airdna-description-wrapper": "_airdna-description-wrapper_147r1w",
  "airdna-description": "_airdna-description_147r1w",
  "heading-medium": "_heading-medium_147r1w",
  "airdna-example-image": "_airdna-example-image_147r1w"
};
