import { getOwner } from '@ember/application';
import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import IndexRoute from 'uplisting-frontend/pods/index/route';

export default class RegisterMeetingRoute extends ProtectedRoute {
  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    const { currentUser } = this;

    if (!currentUser.isAccountOwner) {
      transition.abort();

      const indexRoute = getOwner(this)?.lookup('route:index') as IndexRoute;

      await indexRoute.redirectAfterLogin();
    }
  }
}
